import React, { useState, useRef } from "react"
import starIcon from "../../assets/images/star-icon.png"
import contact from "../../assets/images/contact.png"
import emailjs from "emailjs-com"

const ContactForm = () => {
  const form = useRef()
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    subject: "",
    messege: "",
  })
  const [messageShow, setMessageShow] = useState(false)
  const [message, setMessage] = useState("")
  const [messageType, setMessageType] = useState("red")

  // const handle_change = e => {
  //   setInputValues({
  //     ...inputValues,
  //     [e.target.name]: e.target.value,
  //   })
  //   setMessageShow(false)
  //   setMessage("")
  // }

  const sendEmail = e => {
    e.preventDefault()
    emailjs
      .sendForm(
        "service_76iioue",
        "template_x1yc2n7",
        e.target,
        "user_rAvKQYqQ4oqfOmmeX6DX7"
      )
      .then(
        result => {
          console.log(result.text)
          setMessage("Message has sent successfully")
          setMessageShow(true)
          setMessageType("green")
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
    setTimeout(() => {
      setMessage("")
      setMessageShow(false)
      setMessageType("")
    }, 5000)
  }

  // const sendEmail = () => {
  // if (inputValues.name === "") {
  //   setMessage("name is required")
  //   setMessageShow(true)
  //   setMessageType("red")
  // } else if (inputValues.email === "") {
  //   setMessage("email address is required")
  //   setMessageShow(true)
  //   setMessageType("red")
  // } else if (inputValues.subject === "") {
  //   setMessage("subject is required")
  //   setMessageShow(true)
  //   setMessageType("red")
  // } else {
  //   fetch("https://iskillers-web-nodemailer.herokuapp.com/route/sendemail", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       subject: inputValues.subject,
  //       html: `<div>
  //         <div><b>Name:</b> ${inputValues.name}</div>
  //         <div><b>Email:</b> ${inputValues.email}</div>
  //         <div><b>Mobile Number:</b> ${inputValues.mobileNumber}</div>
  //         <div><b>Subject:</b> ${inputValues.subject}</div>
  //         <div><b>Message:</b> ${inputValues.messege}</div>
  //         </div>`,
  //     }),
  //   })
  //     .then(responece => responece.json())
  //     .then(res => {
  //       setMessage("Message has sent successfully")
  //       setMessageShow(true)
  //       setMessageType("green")
  //       setInputValues({
  //         name: "",
  //         email: "",
  //         mobileNumber: "",
  //         subject: "",
  //         messege: "",
  //       })
  //     })
  // }
  // setTimeout(() => {
  //   setMessage("")
  //   setMessageShow(false)
  //   setMessageType("red")
  // }, 5000)
  // }
  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="contact" />
            Get in Touch
          </span>
          <h2>Request A Free Consultation</h2>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form ref={form} id="contactForm" onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Your name"
                        required
                        // value={inputValues.name}
                        // onChange={handle_change}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your email address"
                        name="email"
                        required
                        // value={inputValues.email}
                        // onChange={handle_change}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="number"
                        name="phone"
                        // value={inputValues.mobileNumber}
                        // onChange={handle_change}
                        className="form-control"
                        placeholder="Number (optional)"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        // value={inputValues.subject}
                        // onChange={handle_change}
                        className="form-control"
                        placeholder="Subject"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        cols="30"
                        rows="6"
                        placeholder="Your message (optional)"
                        // value={inputValues.messege}
                        // onChange={handle_change}
                      />
                    </div>
                  </div>
                  {messageShow ? (
                    <div className="col-lg-12 col-md-12">
                      <p style={{ color: messageType }}>{message}</p>
                    </div>
                  ) : null}

                  <div className="col-lg-12 col-md-12">
                    <button
                      type="submit"
                      className="default-btn"
                      // onClick={() => sendEmail()}
                    >
                      Send Message <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
